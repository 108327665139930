// Header.js
import React, { useState } from "react";
import { Modal, Box, Typography, Grid, TextField, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import "./footer.css";

import axios from "axios";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleLogout = (e) => {
  //   e.preventDefault();

  //   const BASE_URL = process.env.REACT_APP_API_URL;
  //   window.location.href = `${BASE_URL}/auth/logout`;
  // };

  const handleMailing = (e) => {
    e.preventDefault();

    const BASE_URL = process.env.REACT_APP_API_URL;
    axios
      .post(`${BASE_URL}/open/mailing`, formData)
      .then((res) => {
        console.log("success");
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenAlert(true);
        setTextAlert(err.response.data);
      });
  };

  return (
    <footer className="footer py-2 bg--black color--white mt-5">
      <div className="container">
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            {openAlert && (
              <Alert severity="error" onClose={() => setOpenAlert(false)}>
                {textAlert ?? "Failed to join you in, We're sorry."}
              </Alert>
            )}
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Join our mailing list
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You can get notification about our latest products.
            </Typography>
            <form onSubmit={handleMailing}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                style={{ backgroundColor: "#2b2d30" }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
        <div className="footer-content">
        <div>
            <a
              href="https://wordpress-1057385-3705303.cloudwaysapps.com/"
              className="logo mx-auto mx-lg-0 mb-4"
            >
              <h3 className="footer__title color--white mt-0 text--caps mb-2 mb-md-3 logo-policritic">
                POLICRITIC
              </h3>
            </a>
          </div>
          <div>
            <span>
            <a href="#modal" onClick={handleOpen}>
                        Join Our Mailing List
            </a>
            </span>

            <span className="copyright mb-4 mb-lg-0 d-inline-block">
                © Copyright 2024 POLICRITIC |{" "}
                <a
                  href="https://wordpress-1057385-3705303.cloudwaysapps.com/terms-of-service/"
                  className="color--white"
                >
                  Terms of Service
                </a>
              </span>

            
          </div>
        </div>
        {/* <div className="row">

          
          <div className="col-lg-8 mb-4">
            <div className="row justify-content-between">
              
              <div className="col-6 col-md-auto mb-4 mb-lg-0">
             
                <nav className="footer-nav">
                  <ul className="footer-nav__menu">
                    <li>
                      
                    </li>
                    
                  </ul>
                </nav>
              </div>
              
            </div>
          </div>
          <div className="col-lg-12 text-center pt-4">
            <div>
              
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
