import React from "react";
import { useId } from "react";
import { Rating, Stack, Typography } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

import { GrInstagram } from "react-icons/gr";

import { FaShareAlt } from "react-icons/fa";
import {
 
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

// <FaLinkedin />

const PREFIX = "ReviewDetail";
const classes = {
  reviewsContainer: `${PREFIX}-reviewsContainer`,
  titleReviews: `${PREFIX}-titleReviews`,
  reviewItemContainer: `${PREFIX}-reviewItemContainer`,
  reviewerName: `${PREFIX}-reviewerName`,
  sectionReviewInfo: `${PREFIX}-sectionReviewInfo`,
  rating: `${PREFIX}-rating`,
  customParagraph: `${PREFIX}-customParagraph`,
  ratingDistibutionOveral: `${PREFIX}-ratingDistibutionOveral`,
  ratingDistibutionItem: `${PREFIX}-ratingDistibutionItem`,
  quality: `${PREFIX}-quality`,
  valueRated: `${PREFIX}-valueRated`,
  date: `${PREFIX}-date`,
  activePage: `${PREFIX}-activePage`,
};

const theme2 = createTheme();

const ReviewsItemContainer = styled(Stack)(({ theme }) => ({
  [`&.${classes.reviewItemContainer}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap:'wrap',
    padding: "1em 0.5em 1em 0.5em",
    borderRadius: "2px",
    margin: "1em auto",
    width: "100%",
    color:'#545454',
    position:'relative',
    backgroundColor:'#FAFAFA',
    
  },
  [`& .${classes.reviewerName}`]: {
    
    fontSize: "1.5rem",
    fontWeight: "bold",
    lineHeight: "2rem",
    
    
  },
  [`&.${classes.sectionReviewInfo}`]: {
    display: "flex",
    flexDirection: "row",
    padding: "0.5em 0",
    fontWeight: "600",
  },
  [`&.${classes.rating}`]: {
    "&.MuiRating-iconFilled": {
      color: "var(--alert-color, #E77C40)",
    },
  },
  [`& .${classes.customParagraph}`]: {
    color: " var(--second-text-color, #737373)",
    fontSize: "0.85rem",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "0.0125rem",
    margin: "1em 0 1em 0.2em",
  },
  [`& .${classes.ratingDistibutionOveral}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap:'wrap',
    justifyContent: "space-between",
    padding: "1em 0em 1em 0em",
    width:'100%',
    maxWidth:'700px',
    gap:'1em',
    flex:'1',
    minWidth:'250px'
  },
  [`& .${classes.ratingDistibutionItem}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
    backgroundColor:'#EBEAEA',
    padding:'0.4em',
    width:'100%',
    maxWidth:'100px'
    
    
  },
  
  [`& .${classes.quality}`]: {
    fontSize: "0.5rem",
    fontWeight: "600",
    color:'#787676',
    textAlign:'center'
  },
  [`& .${classes.valueRated}`]: {
    borderRadius: "4px",
    fontSize: "2rem",
    fontWeight: "bold",
    alignSelf: "center",
    color:'#545454'
  },
  [`& .${classes.date}`]: {
    alignSelf: "flex-end",
    fontSize: "0.6rem",
    fontWeight:'600',
    position:'absolute',
    right:'1%',
    top:'2%'
  },
  [`&.${classes.activePage}`]: {
    color: "#4FD3D3",
    border: "1px solid #4FD3D3",
  },
  [`&.${classes.firstSectionReview}`]: {
    display:'flex',
    backgroundColor:'green'
  },
  "@media (min-width:750px)": {
    
      [`& .${classes.ratingDistibutionItem}`]: {
       width:'100%',
        maxWidth:'300px',
        minWidth:'180px',
        display:'flex',
        flexDirection:'row-reverse'
        ,alignItems:'center',
        
        flex:'1',
        

        
        
        
      },
      [`& .${classes.ratingDistibutionItem} > *:first-child`]: {
            flex:'1',
        
      },
      [`& .${classes.ratingDistibutionOveral}`]: {
        gap:'2em',
        justifyContent:'center',
        maxWidth:'600px',
        marginLeft:'auto',
        minWidth:'400px'
      }
   
},
"@media (min-width:1600px)": {
  [`& .${classes.ratingDistibutionOveral}`]: {
    gap:'2em',
    justifyContent:'center',
    maxWidth:'60%',
    
  },
  [`& .${classes.quality}`]: {
    fontSize:'.9rem'
  },
  [`& .${classes.valueRated}`]: {
    fontSize:'2.5rem',
    padding:'0 0.2em'
  }

}
}));

const ReviewsContainer = styled(Stack)(({ theme }) => ({
  [`&.${classes.reviewsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em",
    width:'100%',

  },
  [`& .${classes.titleReviews}`]: {
    color: "#545454",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "2rem",
    letterSpacing: "0.00625rem",
    alignSelf: "center",
    justifySelf: "flex-start",
    textAlign:'center'
  },
}));

const ReviewsDetail = ({reviews}) => {
  
  const [isScreenSmall, setIsScreenSmall] = React.useState(false);
  const [showPopUpSocial,setshowPopUpSocial] = React.useState([])
  
  
 
  
  React.useEffect(() => {
    setshowPopUpSocial(()=>{
     
      return reviews.map((review)=>{
        
        return {
          id:review._id,
          isShown:false
        }
      })
    })
    

    
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 700); // Change 768 to your desired breakpoint
    };

    // Call handleResize initially and add event listener for window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[reviews]); 
  
  
  
  const  handlShareClick =(id)=>{
    setshowPopUpSocial((pv)=>{
      
      return pv.map((rev)=>{ 
        return {...rev,      
                  isShown:rev.id===id ? !rev.isShown: rev.isShown
                }
      })
    });
  }
  
 
  
  const idReview = useId();
  const contentReviewContainer = reviews.map((review) => {
    
    
    const popUpRev = showPopUpSocial.filter((r) => r.id===review._id )[0]
    

    let overallRatingByPerson =
      (review.respondsToConstituents +
        review.demonstratesIntegrity +
        review.effectivePolicies +
        review.representsMyInterests) /
      4;

    const ratingDistComponent = ({ rating, bgColor, ratingType }) => {
      
      return (
        <Stack
          className={classes.ratingDistibutionItem}
          key={`${idReview}-${review.id}-${ratingType}`}
        >
          
          <Stack direction={'column'} width={'100%'} maxWidth={'100px'}>
          <Typography variant="p" className={classes.quality}>
            {ratingType}
          </Typography>
          <Rating
                        name="read-only"
                        value={rating}
                        readOnly
                        size="small"
                        className={classes.rating}
                    />
          </Stack>
          <Typography
            variant="p"
            className={classes.valueRated}
          >
            {rating.toFixed(0)}
          </Typography>
        </Stack>
      );
    };

    const ratingDistibutionContainer = [
      ratingDistComponent({
        rating: review.respondsToConstituents,
        bgColor: "#FAC0B8",
        ratingType: "Responds to Constituents",
      }),
      ratingDistComponent({
        rating: review.demonstratesIntegrity,
        bgColor: "#F1FAB8",
        ratingType: "Demonstrates Integrity",
      }),
      ratingDistComponent({
        rating: review.effectivePolicies,
        bgColor: "#B8FABF",
        ratingType: "Effective Policies",
      }),
      ratingDistComponent({
        rating: review.representsMyInterests,
        bgColor: "#B8FABF",
        ratingType: "Represents My Interests",
      }),
    ];
    console.log(review);

    const WEB_URL = window.location.host;
    const shareUrl = `${WEB_URL}/card/${review.politician}/${review._id}`;
    
    return (
      <ReviewsItemContainer
        className={classes.reviewItemContainer}
        key={`${idReview}-${review._id}`}
      >
       
        <Typography variant="p" className={classes.date}>
        
          {review.created.split("T")[0]}
          
        </Typography>
        {/* onClick={() => handlShareClick(review._id)} */}
        <span className="sharebtn" onClick={()=>{handlShareClick(popUpRev.id)}} ><FaShareAlt /></span>
        <Stack  direction={'column'} width={'100%'} flex={'1'} justifyContent={'space-around'}>
            <Stack direction={'row'}  className={'review-container'}>
                    <Typography variant="p" className={classes.reviewerName}>
                        {/* {review.user.firstName} {review.user.lastName} */}
                        {review.user ? `${review.user.firstName} ${review.user.lastName}`: 'unknown'}
                    </Typography>
          
                    <Rating
                        name="read-only"
                        value={overallRatingByPerson}
                        readOnly
                        size="medium"
                        className={classes.rating}
                    />
            </Stack>
          <Typography variant="p" className={classes.customParagraph}>
          {review.content}
        </Typography>
        {/* isScreenSmall && showPopUpSocial.filter((r) => r.id===review._id )[0].isShown */}
        <Stack direction={'row'} width={'100%'} maxWidth={'210px'} className={  isScreenSmall && popUpRev.isShown  ?"social-links show" : "social-links" }>
          <div className="closecontainer">
          <span className={  isScreenSmall && popUpRev.isShown  ?"closebtn show" : "closebtn" }  onClick={()=>{handlShareClick(popUpRev.id)}}><IoMdCloseCircle /></span>
          </div>
          
              <LinkedinShareButton url={shareUrl}> <FaLinkedin /></LinkedinShareButton>
              <FacebookShareButton url={shareUrl}><FaSquareFacebook /></FacebookShareButton>
              <TwitterShareButton url={shareUrl}><FaSquareXTwitter /></TwitterShareButton>
              <InstapaperShareButton url={shareUrl}><GrInstagram /></InstapaperShareButton> 
            </Stack>
        </Stack>
        
        <Stack direction={"row"} flexWrap={'wrap'} className={classes.ratingDistibutionOveral}>
          {ratingDistibutionContainer}
        </Stack>
      </ReviewsItemContainer>
    );
  });

  return (
    <ThemeProvider theme={theme2}>
      <ReviewsContainer className={classes.reviewsContainer}>
        <Typography
          variant="p"
          className={classes.titleReviews}
          margin={"1em 0"}
        >
          Individual Rating & Reviews
        </Typography>
        <div className='reviewsList'>
            {contentReviewContainer}
        </div>
        
      </ReviewsContainer>
    </ThemeProvider>
  );
};

export default ReviewsDetail;
