// src/App.js
import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import CardList from "./components/Card/CardList";
import CardDetail from "./components/Card/CardDetail";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import LoginPage from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import PhoneNumVerify from "./components/Signup/PhoneNumVerify";
import UserProfile from "./components/UserProfile/UserProfile";

function App() {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const BASE_URL = process.env.REACT_APP_API_URL;

    axios
      .get(`${BASE_URL}/open/politicians`, { signal: controller.signal })
      .then((res) => {
        console.log(res.data)
        setCardData(res.data);
      })
      .catch((err) => console.log(err));

    return () => controller.abort();

  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<CardList cards={cardData} />} />
            <Route path="/card/:id" element={<CardDetail cards={cardData} />} />
            <Route
              path="/card/:id/:review_id"
              element={<CardDetail cards={cardData} />}
            />
            <Route path="/candidates" element={<CardList cards={cardData} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify/:phone" element={<PhoneNumVerify />} />
            <Route path="/userprofile" element={<UserProfile />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
