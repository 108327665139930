import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { AppBar, List, Toolbar, Typography, styled, useMediaQuery, useTheme, IconButton, Drawer, ListItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const Logo = styled(Typography)({
  fontWeight: '900',
  fontSize: '1.5rem',
  color: '#000',
  textDecoration: 'none',
  

});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const ListNav = styled(List)({
  display: "flex",
  flexDirection: "row",
  gap: "1em",
  color: "#737373"
});

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const linkStyle = {
    gap: "1em",
    color: 'var(--second-text-color, #737373)',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '0.0125rem',
    whiteSpace: 'nowrap', // Prevent text from wrapping
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar position="sticky" style={{ background: '#fff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <StyledToolbar>
        <div className="logo">
          <Link to="/" style={{ textDecoration:'none'}}>
            <Logo variant={isSmallScreen ? "h4" : "h2"}>POLICRITIC</Logo>
          </Link>
        </div>
        {isSmallScreen ? (
          // Render responsive menu for small screens
          <IconButton color="black" onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
        ) : (
          // Render the list for larger screens
          <ListNav>
            <ListItem>
              <Link to="/candidates" style={linkStyle}>Find Politicians</Link>
            </ListItem>
            <ListItem>
              <Link to="/contact" style={linkStyle}>Contact</Link>
            </ListItem>
            <ListItem>
              <Link to="/login" style={linkStyle}>Login</Link>
            </ListItem>
            <ListItem>
              <Link to="/signup" style={linkStyle}>Sign Up</Link>
            </ListItem>
            <ListItem>
              <Link to="/userprofile" style={linkStyle}>User Profile</Link>
            </ListItem>
          </ListNav>
        )}
        <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu}>
          {/* Add your menu items or links here */}
          <List>
            <ListItem button>
              <Link to="/candidates" style={linkStyle}>Find Politicians</Link>
            </ListItem>
            <ListItem button>
              <Link to="/contact" style={linkStyle}>Contact</Link>
            </ListItem>
            <ListItem button>
              <Link to="/login" style={linkStyle}>Login</Link>
            </ListItem>
            <ListItem button>
              <Link to="/signup" style={linkStyle}>Sign Up</Link>
            </ListItem>
          </List>
        </Drawer>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
