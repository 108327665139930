import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  FormGroup,
  CssBaseline,
} from "@mui/material";
import axios from "axios";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

const PhoneNumVerify = (props) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [code, setCode] = useState("");

  const { phone: phoneNumber } = useParams();

  const handleSendCode = (e) => {
    e.preventDefault();

    const BASE_URL = process.env.REACT_APP_API_URL;
    axios
      .get(`${BASE_URL}/auth/verify-sms/+1${phoneNumber}`)
      .then((res) => {
        setSubmitSuccess(true);
        setCode("");
      })
      .catch((err) => {
        console.log(err);
        setSubmitError(
          `Failed to verify: ${err.response.data.message ?? err.response.data}`
        );
        setSubmitSuccess(false);
      });
  };

  const handleCheck = (e) => {
    e.preventDefault();

    const BASE_URL = process.env.REACT_APP_API_URL;
    axios
      .get(`${BASE_URL}/auth/check-sms/+1${phoneNumber}/${code}`)
      .then((res) => {
        setSubmitSuccess(true);
        setCode("");
        window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
        setSubmitError(
          `Failed to verify: ${err.response.data.message ?? err.response.data}`
        );
        setSubmitSuccess(false);
      });
  };

  return (
    <Container maxWidth="sm" className="mt-5">
      <Container
        component="main"
        maxWidth="lg"
        style={{ "min-height": "500px" }}
      >
        <CssBaseline />
        <Paper
          className="mt-5"
          elevation={3}
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Verification
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
          >
            Verify your phone number
          </Typography>

          <FormGroup row>
            <TextField
              label="Phone Number"
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={phoneNumber}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <Button variant="text" size="small" onClick={handleSendCode}>
                    Send Code
                  </Button>
                ),
              }}
            />
          </FormGroup>

          <TextField
            label="Code"
            fullWidth
            margin="normal"
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button variant="contained" onClick={handleCheck}>
            Verify
          </Button>

          {submitSuccess && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ color: "green" }}>
                Sign Up Successful!
              </Typography>
              <IconButton onClick={() => setSubmitSuccess(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {submitError && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ color: "red" }}>
                {submitError}
              </Typography>
              <IconButton onClick={() => setSubmitError("")}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </Paper>
      </Container>
    </Container>
  );
};

export default PhoneNumVerify;
