import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  FormControl,
  Select,
  Grid,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    countryCode: "+1",
    phoneNumber: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    countryCode: false,
    phoneNumber: false,
    email: false,
    message: false,
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (formData.phoneNumber.length !== 10) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: true,
      }));
      return;
    }

    try {
      const BASE_URL = process.env.REACT_APP_API_URL;
      await axios.post(`${BASE_URL}/contact`, formData);

      setSubmitSuccess(true);
      setFormData({
        firstName: "",
        lastName: "",
        countryCode: "+1",
        phoneNumber: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      setSubmitError("Failed to submit the form. Please try again later.");
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        padding: "16px",
        marginTop:'2em'
      }}
    >
      {submitSuccess && (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <Typography variant="body1" style={{ color: "green" }}>
            Form submitted successfully!
          </Typography>
          <IconButton onClick={() => setSubmitSuccess(false)}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {submitError && (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <Typography variant="body1" style={{ color: "red" }}>
            {submitError}
          </Typography>
          <IconButton onClick={() => setSubmitError("")}>
            <CloseIcon />
          </IconButton>
        </div>
      )}

      <Typography variant="h4" gutterBottom>
        Contact Policritic
      </Typography>
      <Typography variant="body1" paragraph>
        How can we assist you?
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={formErrors.firstName}
              helperText={formErrors.firstName && "First Name is required"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={formErrors.lastName}
              helperText={formErrors.lastName && "Last Name is required"}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              required
              error={formErrors.countryCode}
            >
              <InputLabel htmlFor="countryCode">Country Code</InputLabel>
              <Select
                label="Country Code"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
              >
                <MenuItem value="+1">+1 (Canada)</MenuItem>

                <MenuItem value="+44">+44 (United Kingdom)</MenuItem>
                <MenuItem value="+91">+91 (India)</MenuItem>
                <MenuItem value="+81">+81 (Japan)</MenuItem>
                <MenuItem value="+86">+86 (China)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={formErrors.phoneNumber}
              helperText={
                formErrors.phoneNumber &&
                "Phone number should be 10 digits in length"
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formData.countryCode}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          required
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={formErrors.email}
          helperText={formErrors.email && "Invalid email address"}
        />

        <TextField
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          required
          name="message"
          value={formData.message}
          onChange={handleChange}
          error={formErrors.message}
          helperText={formErrors.message && "Message is required"}
        />

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          
          style={{ 
            backgroundColor: "#6ff",
            color: "black",
          
            width: "100%",
            textAlign: "center",
            borderRadius:'5px'
          }}
        >
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default Contact;
