import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import "./CardDetail.css";
import ReviewsDetail from "../Reviews/ReviewsDetail.js";
import CommentsForm from "../Comments/CommentsForm";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { Typography, Rating, Stack, Box } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import OverallRatingComponent  from "./OverallRatingComponent.js";
import SEO from "../SEO.js";



const PREFIX = "CardDetail";
const classes = {
  paginationBox: `${PREFIX}-paginationBox`,
  politianInfoStack: `${PREFIX}-politianInfoStack`,
  totalRating: `${PREFIX}-totalRating`,
  customizedIcons: `${PREFIX}-customizedIcons`,
  styledLinkP: `${PREFIX}-styledLinkP`,
  ratingDistributionContainer: `${PREFIX}-ratingDistributionContainer`,
  ratingDistribution: `${PREFIX}-ratingDistribution`,
  ratingDistributionItem: `${PREFIX}-ratingDistributionItem`,
  ratingDistributionItemStars: `${PREFIX}-ratingDistributionItemStars`,
  ratingDistibutionTitle: `${PREFIX}-ratingDistibutionTitle`,
  greenProgressBar: `${PREFIX}-greenProgressBar`,
};

const theme = createTheme();

const PaginationBox = styled(Box)(({ theme }) => ({
  [`&.${classes.paginationBox}`]: {
    alignSelf: "center",
    justifySelf: "center",
    padding: "1em",
    borderRadius: "5px",
    margin: "0 2px 0 auto",
    display:'flex',
    flexDirection:'row',
    justifyContent:'center'
  },
}));

const PolitianInfoStack = styled(Stack)(({ theme }) => ({
  [`&.${classes.politianInfoStack}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "500px",
    textAlign: "center",
    alignItems: "center",
    justifySelf: "flex-start",
    alignSelf: "center",
    backgroundColor: "#FAFAFA",
    borderRadius: "5px",
    margin: "1em auto",
    padding: "0.5em",
    color: "#545454",

    "& > *": {
      textAlign: "center",
      margin: "0.95em 0",
      fontSize: "1rem",
      fontStyle: "normal",
      lineHeight: "1.5rem",
      letterSpacing: "0.0125rem",
      width: "100%",
    },
  },

  [`& .${classes.customizedIcons}`]: {
    "& .MuiRating-iconFilled": {
      // color: "var(--alert-color, #E77C40)",
    },
  },
  [`& .${classes.styledLinkP}`]: {
    color: "#545454",
    textDecoration: "none",
    fontSize: "1rem",
    cursor: "pointer",
    fontWeight:'600',
    margin: "0.4em 0",
    "&:active, &:focus, &:hover": {
      color: "#545454",
      textDecoration: "none",
    },
  },
  "@media (min-width:1600px)": {
    [`&.${classes.politianInfoStack}`]: {
      maxWidth: "40%",
      "& > *": {
        fontSize: "1.3rem !important",
        fontStyle: "normal",
        lineHeight: "2rem",
      },
    },
    [`& .${classes.styledLinkP}`]: {
      fontSize: "1.3rem",
    },
  },
}));

const CustomTypography = styled(Typography)({
  fontSize: "1.563rem",
  fontStyle: "normal",
  textTransform: "capitalize",
  fontWeight: 700,
  letterSpacing: "0.00625rem",
  margin: "0.5em auto 1em",
  "@media (min-width:1600px)": {
    fontSize: "2rem",
  },
});

const CustomParagraph = styled(Typography)({
  fontSize: "0.7rem",
  fontWeight:'600',
  lineHeight: "1.625rem",
  "@media (min-width:1600px)": {
    marginTop: "1em",
    fontSize: "1rem",
  },
});

const RatingDistributionContainer = styled(Stack)((theme) => ({
  [`&.${classes.ratingDistributionContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    justifyItems: "center",
    textAlign: "start",
    borderRadius: "0.3125rem",
    margin: "2em 0 1em",
    width: "100%",
    minWidth: "250px",
    flex: "1",
  },
  [`& .${classes.ratingDistribution}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignSelf: "flex-end",
  },
  [`& .${classes.ratingDistributionItem}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "0.8rem",
    fontStyle: "normal",

    fontWeight: 600,
    color: "var(--text-color, #252B42)",
    "& > :first-child": {
      fontSize: "0.6rem",
      minWidth: "140px",
    },
    "& > :last-child > :last-child *": {
      fontSize: "1.5rem !important",
    },
  },

  [`& .${classes.ratingDistributionItemStars}`]: {
    display: "flex",
    flexDirection: "row",
    margin: "1px 0 0 auto",
    fontSize: "1.5rem",
    fontWeight: 600,
    flex:'1',

    color: "var(--second-text-color, #737373)",
    "& *": {
      margin: "0 auto 0 auto",
    },
  },
  [`& .${classes.littleparagraph}`]: {
    fontSize: "0.6rem",
  },
  [`& .${classes.ratingDistibutionTitle}`]: {
    fontSize: "1.25rem",
    fontWeight: "bolder",
    alignSelf: "flex-start",
    color: "#000",
    margin: "1.1em 0",
    width: "100%",
    "& > *": {
      color: "#545454",
    },
  },
  [`& .${classes.greenProgressBar}`]: {
    height: "0.375rem",
    borderRadius: "1.25rem",
    flex: 1,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#4FD3D3",
    },
  },
  [`& .${classes.customizedIcons}`]: {
    "& .MuiRating-iconFilled": {
      // color: "var(--alert-color, #E77C40)",
    },
  },
  "@media (max-width:370px)": {
    [`& .${classes.ratingDistributionItem}`]: {
      flexWrap: "wrap",
    },
    
  },
  "@media (max-width:300px)": {
    [`& .${classes.ratingDistributionItemStars}`]: {
      "& *": {
        margin: "0 auto 0 0",
      },
    }
  },
  "@media (min-width:1600px)": {
    [`& .${classes.ratingDistributionItem}`]: {
      "& > :first-child": {
        fontSize: "1rem !important",
        minWidth: "212px",
      },
      "& > :last-child": {
        flex: "1",
      },
      "& > :last-child > :last-child *": {
        fontSize: "1.8rem !important",
      },
    },
  },
  [`& .${classes.ratingDistributionItemStars}`]: {
    margin:'0',
    marginLeft:'auto',
    "& *": {
      margin: "0 2px 0 auto",
    }
  }
}));

const fetcher = (url) => axios.get(url).then((res) => res.data);
const BASE_URL = process.env.REACT_APP_API_URL;
const REVIEWS_PER_PAGE = 10;

const CardDetail = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const { id, review_id: reviewId = null } = useParams();
  const cardData = props.cards;
  const card = cardData.find((c) => c._id === id);
  const filteredCardsParty = cardData.filter((c) => c.Party === card.Party);

  const {
    data: reviews,
    error: reviewsError,
    isLoading: reviewsIsLoading,
    mutate: reviewsMutate,
  } = useSWR(`${BASE_URL}/open/reviews/${id}`, fetcher);

  React.useEffect(() => {
    const politicianId = id;
    // GET overall rating of all reviews under current politician
    axios
      .get(`${BASE_URL}/open/reviews-overall-rating/${politicianId}`)
      .then((res) => {
        console.log("reviews-overall-rating/id", res.data);
        setRatingDist((prevData) => ({
          ...prevData,
          ...res.data,
        }));
      })
      .catch((err) => console.log(err));
  }, [id]);

  React.useEffect(() => {
    // Put the specific review to the first one of the review list
    if (reviewsError || reviewsIsLoading || reviewId === null) {
      return;
    }
    for (let i = 0; i < reviews.length; i++) {
      if (reviews[i]._id !== reviewId) {
        continue;
      }
      var tmp = reviews[i];
      reviews.splice(i, 1);
      reviews.splice(0, 0, tmp);
      break;
    }
  }, [reviews, reviewsIsLoading, reviewsError, reviewId]);

  const [page, setPage] = React.useState(1);
  const [indexes, seIndexes] = React.useState({
    firstIndex: page * REVIEWS_PER_PAGE - REVIEWS_PER_PAGE,
    lastIndex: page * REVIEWS_PER_PAGE,
  });

  const [data_reviews, setData_Reviews] = React.useState([]);
  const [npages, setNpages] = React.useState(0);

  React.useEffect(() => {
    if (reviewsError || reviewsIsLoading) {
      return;
    }
    setData_Reviews(reviews.slice(indexes.firstIndex, indexes.lastIndex));
    setNpages(Math.ceil(reviews.length / REVIEWS_PER_PAGE));
  }, [indexes, reviews, reviewsError, reviewsIsLoading]);

  const handleChange = (event, value) => {
    setPage(value);
    seIndexes({
      firstIndex: value * REVIEWS_PER_PAGE - REVIEWS_PER_PAGE,
      lastIndex: value * REVIEWS_PER_PAGE,
    });
  };

  const [ratingDist, setRatingDist] = React.useState({
    respondsToConstituents: 0,
    demonstratesIntegrity: 0,
    effectivePolicies: 0,
    representsMyInterests: 0,
    count: 0,
  });
  const overalRating =
    (ratingDist.respondsToConstituents +
      ratingDist.demonstratesIntegrity +
      ratingDist.effectivePolicies +
      ratingDist.representsMyInterests) /
    4;

  const nbrRatingInsert = ratingDist.count;

  const currPageUrl = window.location.href;

  if (!card) {
    return <div>
      <SEO
        title={"Policritic | Reviews"}
        description={"Review for Canadian Policians"}
        name={"Policritic"}
        type={"summary"}
        img={""}
        url={currPageUrl}
      />
      <p>Card not found</p>
      </div>;
  }

  const metadataDescription = card.Bio.split(" ").slice(0, 20).join(" ");

  return (
    <ThemeProvider theme={theme}>
      <SEO
        title={`Policritic | Reviews on ${card.Name}`}
        description={metadataDescription}
        name={"Policritic"}
        type={"summary"}
        img={card.Picture}
        url={currPageUrl}
      />
      {/* <ReviewContainer className="mt-4 politician-details" spacing={2}> */}
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <PolitianInfoStack className={classes.politianInfoStack}>
          <Stack
            alignSelf={"center"}
            maxWidth={"400px"}
            direction="column"
            alignItems={"center"}
          >
            {
              <Avatar
                alignSelf={"center"}
                src={card.Picture}
                alt={`${card.Name}`}
                sx={{ width: "9.875rem", height: "9.625rem" }}
              />
            }
          </Stack>
          <Stack alignSelf={"center"}>
            <CustomTypography variant="h1">{card.Name}</CustomTypography>

            <Link
              to="/"
              state={{ Party: filteredCardsParty, value: card.Party }}
              className={classes.styledLinkP}
            >
              Party:
              {card.Party}
            </Link>
            {/* <Link
              to="/"
              state={{ Riding: filteredCardsRiding, value: card.Riding }}
              className={classes.styledLinkP}
            >
              Riding:
              {card.Riding}
            </Link> */}
            <CustomParagraph variant="p">
              <p>
                {card.Bio.split(" ").length > 50
                  ? !expanded
                    ? card.Bio.split(" ").slice(0, 50).join(" ")
                    : card.Bio
                  : card.Bio}
                {card.Bio.split(" ").length > 50 && (
                  <span
                    className="see-more"
                    onClick={() => {
                      setExpanded((prev) => {
                        return !prev;
                      });
                    }}
                  >
                    {expanded ? "See Less" : "See More"}
                  </span>
                )}
              </p>
            </CustomParagraph>
          </Stack>
        </PolitianInfoStack>
        <Stack
          direction="column"
          alignItems={"center"}
          width={"100%"}
          flex={1}
          maxWidth={"800px"}
          height={"auto"}
          padding={"1em"}
          margin={"auto"}
          className="rightContainerReviewOverall"
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            className={"Rating-Distribution-wrapper"}
          >
            <Typography variant="p" className={classes.ratingDistibutionTitle}>
              Rating Distribution
            </Typography>
            
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              flexWrap="wrap"
              spacing={2}
              justifyContent="space-between"
            >
              <OverallRatingComponent overalRating={overalRating} nbrRatingInsert={nbrRatingInsert}  />
              {/* <Stack
                className="totalRating"
                direction={"row"}
                sx={{ width: "100%", maxWidth: "250px" }}
              >
                <h2 className="totalreview">{overalRating.toFixed(1)}</h2>
                <Stack direction="column" sx={{ marginLeft: "0" }}>
                  <Rating
                    name="read-only"
                    value={overalRating}
                    readOnly
                    className={classes.customizedIcons}
                    size="medium"
                  />

                  <Typography variant="p" className="p-rating-total">
                    Based on {nbrRatingInsert} ratings
                  </Typography>
                </Stack>
              </Stack> */}
              <RatingDistributionContainer
                className={classes.ratingDistributionContainer}
              >
                <Stack className={classes.ratingDistribution}>
                  <Box className={classes.ratingDistributionItem}>
                    <Typography variant="p" whiteSpace={"nowrap"}>
                      Responds to Constituents
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        width: "100%"
                      }}
                      className={classes.ratingDistributionItemStars}
                    >
                      
                      <Rating
                        name="read-only"
                        value={ratingDist.respondsToConstituents}
                        readOnly
                        className={classes.customizedIcons}
                        size="large"
                      />
                    </Box>

                    {/* <Box sx={{ width: "100%" }}>
                      <LinearProgressWithLabel
                        value={(ratingDist.respondsToConstituents / 5) * 100}
                      />
                    </Box> */}
                  </Box>

                  <Box className={classes.ratingDistributionItem}>
                    <Typography variant="p" whiteSpace={"nowrap"}>
                      Demonstrates Integrity
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        marginLeft: "auto",
                        width: "100%"
                      }}
                      className={classes.ratingDistributionItemStars}
                    >
                      
                      <Rating
                        name="read-only"
                        value={ratingDist.demonstratesIntegrity}
                        readOnly
                        className={classes.customizedIcons}
                        size="large"
                      />
                    </Box>

                    {/* <Box sx={{ width: "100%" }}>
                      <LinearProgressWithLabel
                        value={(ratingDist.demonstratesIntegrity / 5) * 100}
                      />
                    </Box> */}
                  </Box>
                  <Box className={classes.ratingDistributionItem}>
                    <Typography variant="p" whiteSpace={"nowrap"}>
                      Effective Policies{" "}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        marginLeft: "auto",
                        width: "100%"
                      }}
                      className={classes.ratingDistributionItemStars}
                    >
                      
                      <Rating
                        name="read-only"
                        value={ratingDist.effectivePolicies}
                        readOnly
                        className={classes.customizedIcons}
                        size="large"
                      />
                    </Box>
                    {/* <Box sx={{ width: "100%" }}>
                      <LinearProgressWithLabel
                        value={(ratingDist.effectivePolicies / 5) * 100}
                      />
                    </Box> */}
                  </Box>
                  <Box className={classes.ratingDistributionItem}>
                    <Typography variant="p" whiteSpace={"nowrap"}>
                      Represents my Interests
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: "1",
                        marginLeft: "auto",
                        width: "100%"
                      }}
                      className={classes.ratingDistributionItemStars}
                    >
                      
                      <Rating
                        name="read-only"
                        value={ratingDist.representsMyInterests}
                        readOnly
                        className={classes.customizedIcons}
                        size="medium"
                      />
                    </Box>
                    {/* <Box sx={{ width: "100%" }}>
                      <LinearProgressWithLabel
                        value={(ratingDist.representsMyInterests / 5) * 100}
                      />
                    </Box> */}
                  </Box>
                </Stack>
              </RatingDistributionContainer>
            </Stack>
          </Stack>

          <CommentsForm card={card} refetch={reviewsMutate} />
        </Stack>
      </Stack>
      {data_reviews.length!==0 ? <ReviewsDetail reviews={data_reviews} /> : <></> }
      
      {/* </ReviewContainer> */}
      <PaginationBox className={classes.paginationBox}>
        <Pagination
          count={npages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              style={
                item.page === page
                  ? {
                      color: "#4FD3D3",
                      border: "2px solid #4FD3D3",
                      backgroundColor: "#fff",
                    }
                  : {}
              }
            />
          )}
        />
      </PaginationBox>
    </ThemeProvider>
  );
};

export default CardDetail;
