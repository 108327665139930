import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CssBaseline,
  IconButton,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

const UserProfile = () => {
  const [userData, setUserData] = useState({
    firstName: "John",
    lastName: "Doe",
    email: "JohnDoe@gmail.com",
    password: "",
    confirmPassword: "",
    dob: "",
    gender: "Male",
    address: "",
    city: "",
    province: "",
    country: "",
    postalCode: "1234",
    phoneNumber: "1234560000",
    role: "voter",
  });

  // Maintain separate state for each field to track if it is editable
  const [editableFields, setEditableFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    ageRange: false,
    gender: false,
    postalCode: false,
    role: false,
    // Add other fields here with their default editable state
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  // Fetch user data from API when the component mounts
  useEffect(() => {
    // Fetch user data from your API and update state
    const fetchUserData = async () => {
      try {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${BASE_URL}/api/profile`, {
          withCredentials: true,
        });
        setUserData((prevData) => ({
          ...prevData,
          ...response.data,
        }));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = (fieldName) => {
    // Enable editing only for the clicked field
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: true,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Assume update profile logic and userData are defined elsewhere
    console.log("Submitting updated profile:", userData);

    const BASE_URL = process.env.REACT_APP_API_URL;
    axios
      .patch(`${BASE_URL}/api/profile`, userData, {
        withCredentials: true,
      })
      .then((response) => {
        setSubmitSuccess(true);
        setEditableFields({
          firstName: false,
          lastName: false,
          email: false,
          // Add other fields here with their default editable state
        });
      })
      .catch((error) => {
        console.error("Error submitting form data:", error.message);
        setSubmitError("Failed to update profile. Please try again later.");
        setSubmitSuccess(false);
      });
  };

  return (
    <Container maxWidth="sm" className="mt-5">
      <Container component="main" maxWidth="sm" style={{ minHeight: "500px" }}>
        <CssBaseline />
        <Paper
          className="mt-5"
          elevation={3}
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Edit Profile
          </Typography>
          <Typography
            variant="p"
            align="center"
            gutterBottom
            style={{ color: "grey" }}
          >
            Edit profile details below
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="First Name"
                  fullWidth
                  margin="normal"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleChange}
                  disabled={!editableFields.firstName}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("firstName")}
                  disabled={editableFields.firstName}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* Repeat the above grid structure for other fields */}
            {/* Example for Last Name */}
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleChange}
                  disabled={!editableFields.lastName}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("lastName")}
                  disabled={editableFields.lastName}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* Add other fields here */}

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Email"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  disabled={!editableFields.email}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("email")}
                  disabled={editableFields.email}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Password"
                  fullWidth
                  margin="normal"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  disabled={!editableFields.password}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("password")}
                  disabled={editableFields.password}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Your Role</InputLabel>
                  <Select
                    name="role"
                    value={userData.role}
                    onChange={handleChange}
                    label="Your Role"
                    disabled={!editableFields.role}
                    // You can add more styling or customization here
                  >
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="politician">Politician</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("role")}
                  disabled={editableFields.role}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={userData.gender}
                    onChange={handleChange}
                    label="Gender"
                    disabled={!editableFields.gender}
                    // You can add more styling or customization here
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("gender")}
                  disabled={editableFields.gender}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Country"
                  fullWidth
                  margin="normal"
                  name="country"
                  value={userData.country}
                  onChange={handleChange}
                  disabled={!editableFields.country}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("country")}
                  disabled={editableFields.country}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="City"
                  fullWidth
                  margin="normal"
                  name="city"
                  value={userData.city}
                  onChange={handleChange}
                  disabled={!editableFields.city}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("city")}
                  disabled={editableFields.city}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Address"
                  fullWidth
                  margin="normal"
                  name="address"
                  value={userData.address}
                  onChange={handleChange}
                  disabled={!editableFields.address}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("address")}
                  disabled={editableFields.address}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="Province"
                  fullWidth
                  margin="normal"
                  name="province"
                  value={userData.province}
                  onChange={handleChange}
                  disabled={!editableFields.province}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  onClick={() => handleEdit("province")}
                  disabled={editableFields.province}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{
                marginTop: 16,
                backgroundColor: "#4fe3e3",
                color: "black",
                borderRadius: "10px", // Adjust the border-radius as needed
                padding: "10px", // Adjust the padding as needed
                // Adjust the font weight as needed
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add box shadow for depth
              }}
              disabled={!Object.values(editableFields).some((field) => field)}
            >
              Update Profile
            </Button>
            {submitSuccess && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" style={{ color: "green" }}>
                  Profile Updated successfully!
                </Typography>
                <IconButton onClick={() => setSubmitSuccess(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            {submitError && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" style={{ color: "red" }}>
                  {submitError}
                </Typography>
                <IconButton onClick={() => setSubmitError("")}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </form>
        </Paper>
      </Container>
    </Container>
  );
};

export default UserProfile;
