// Card.js
import React from "react";
import { Link } from "react-router-dom";
import "./Card.css"
import OverallRatingComponent from "./OverallRatingComponent";

const Card = ({ card }) => (
  <div className="flex-container">
    <div className="card">
      <Link to={`/card/${card._id}`} key={card._id} className="card-link">
        <div className="politician-img-container">
          <div className="politician-mask">
            <div className="politician-image" style={{backgroundImage: `url(${card.Picture
})`}}>
            </div>
          </div>
        </div>
        <div className="politician-info">
          <h3> {card.Name} </h3>
          <p> {card.Party} </p>
          <div>
          <OverallRatingComponent overalRating={4.76} nbrRatingInsert={200} /> 
          </div>
        </div>
      </Link>
    </div>
  </div>
);

export default Card;
