// CardList.js
import React, { useState } from "react";
import Select from "react-select";
import Card from "./Card"; // Import the Card component
import "./CardList.css";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";


const PREFIX = "CardList";
const classes = {
  paginationBox: `${PREFIX}-paginationBox`,
};

const PaginationBox = styled(Box)(({ theme }) => ({
  [`&.${classes.paginationBox}`]: {
    alignSelf: "center",
    justifySelf: "center",
    padding: "1em",
    borderRadius: "5px",
    width: "63%",
    margin: "0 2px 0 auto",
  },
}));

const CARDS_PER_PAGE = 12;

const CardList = (props) => {
  const location = useLocation();
  // const cards = (location && location.state && location.state.Party) || (location && location.state && location.state.Riding) || props.cards;
  const cards = props.cards;

  const [selectedFilters, setSelectedFilters] = useState({
    Name: [],
    Riding:
      location && location.state && location.state.Riding
        ? [{ label: location.state.value, value: location.state.value }]
        : [],
    Party:
      location && location.state && location.state.Party
        ? [{ label: location.state.value, value: location.state.value }]
        : [],
    city: [],
    province: [],
    country: [],
  });
  if (location && location.state && location.state.Party) {
    
    console.log(`hhhhhh party ${location.state.value.firstName}`)
  }

  const options = (filterKey) => {
    if (filterKey === "city") {
        // Return sample data for city
        return [
            { label: "Sample City 1", value: "Sample City 1" },
            { label: "Sample City 2", value: "Sample City 2" },
            // Add more sample city data as needed
        ];
    } else if (filterKey === "province") {
        // Return sample data for province
        return [
            { label: "Sample Province 1", value: "Sample Province 1" },
            { label: "Sample Province 2", value: "Sample Province 2" },
            // Add more sample province data as needed
        ];
    } else {
        // Proceed with the original logic
        const uniqueValues = [...new Set(cards.map((card) => card[filterKey]))];
        return uniqueValues.map((value) => ({ label: value, value }));
    }
};

  console.log(cards)
  // console.log(options("firstName"));

  const handleChange = (filterKey, selectedOptions) => {
    setSelectedFilters({ ...selectedFilters, [filterKey]: selectedOptions });
  };

  const filterCards = (card) => {
    return Object.keys(selectedFilters).every((filterKey) => {
      if (selectedFilters[filterKey].length === 0) return true;
      return selectedFilters[filterKey].some(
        (selectedOption) => selectedOption.value === card[filterKey]
      );
    });
  };

  const filteredCards = cards.filter(filterCards);

  const [page, setPage] = React.useState(1);

  const npages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);
  const firstIndex = page * CARDS_PER_PAGE - CARDS_PER_PAGE;
  const lastIndex = page * CARDS_PER_PAGE;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="card-filters">
        <h1 className="mt-5 ml-5">Find Canadian Politicians</h1>
        <div className="filter-dropdowns">
          <div className="select-row">
            <div className="select-row">
              <Select
                isMulti
                options={options("Name")}
                onChange={(selectedOptions) =>
                  handleChange("Name", selectedOptions)
                }
                value={selectedFilters.firstName}
                placeholder="Filter by Name"
              />
              {/* <Select
                isMulti
                options={options("lastName")}
                onChange={(selectedOptions) =>
                  handleChange("lastName", selectedOptions)
                }
                value={selectedFilters.lastName}
                placeholder="Filter by Last Name"
              /> */}
              {/* <Select
                isMulti
                options={options("Riding")}
                onChange={(selectedOptions) =>
                  handleChange("Riding", selectedOptions)
                }
                value={selectedFilters.Riding}
                placeholder="Filter by Riding"
              /> */}
              <Select
                isMulti
                options={options("Party")}
                onChange={(selectedOptions) =>
                  handleChange("Party", selectedOptions)
                }
                value={selectedFilters.Party}
                placeholder="Filter by Party"
              />
              <Select
                isMulti
                options={options("city")}
                onChange={(selectedOptions) =>
                  handleChange("city", selectedOptions)
                }
                value={selectedFilters.city}
                placeholder="Filter by City"
              />
              <Select
                isMulti
                options={options("province")}
                onChange={(selectedOptions) =>
                  handleChange("province", selectedOptions)
                }
                value={selectedFilters.province}
                placeholder="Filter by Province"
              />
              {/* <Select
                isMulti
                options={options("country")}
                onChange={(selectedOptions) =>
                  handleChange("country", selectedOptions)
                }
                value={selectedFilters.country}
                placeholder="Filter by Country"
              /> */}
            </div>
          </div>
        </div>
        <div
          className="card-list"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {filteredCards.slice(firstIndex, lastIndex).map((card) => (
            <Card
              key={card._id}
              card={card}
              style={{ flex: "1 0 calc(25% - 1rem)" }}
            />
          ))}
        </div>
      </div>
      <PaginationBox className={classes.paginationBox}>
        <Pagination
          count={npages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              style={
                item.page === page
                  ? {
                      color: "#4FD3D3",
                      border: "2px solid #4FD3D3",
                      backgroundColor: "#fff",
                    }
                  : {}
              }
            />
          )}
        />
      </PaginationBox>
    </>
  );
};

export default CardList;
