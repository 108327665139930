import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  CssBaseline,
} from "@mui/material";

import axios from "axios";
import { Avatar, IconButton, Link } from "@mui/material";
import { Facebook, Google } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
const Signup = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dob: "",
    postalCode: "",
    address: "",
    city: "",
    province: "",
    country: "",
    phoneNumber: "",
    role: "voter",
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your signup logic here
    if (formData.password !== formData.confirmPassword) {
      console.log("confirmed password error!");
      return;
    }

    const BASE_URL = process.env.REACT_APP_API_URL;
    axios
      .post(`${BASE_URL}/auth/signup`, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        gender: formData.gender,
        dob: formData.dob.toISOString(),
        postalCode: formData.postalCode.replace(" ", ""),
        address: formData.address,
        city: formData.city,
        province: formData.province,
        country: formData.country,
        phoneNumber: formData.phoneNumber,
        role: formData.role,
      })
      .then((res) => {
        setSubmitSuccess(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
        setSubmitError(`Failed to signup: ${err.response.data.message ?? err.response.data}`);
        setSubmitSuccess(false);
      });
  };

  const handleFacebook = (e) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    window.location.href = `${BASE_URL}/auth/facebook`;
  };

  const handleGoogle = (e) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    window.location.href = `${BASE_URL}/auth/google`;
  };

  return (
    <Container maxWidth="sm" className="mt-5">
      <Container
        component="main"
        maxWidth="lg"
        style={{ "min-height": "500px" }}
      >
        <CssBaseline />
        <Paper
          className="mt-5"
          elevation={3}
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Sign Up
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
          >
            Sign Up With
          </Typography>
          
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} style={{ textAlign: "right" }}>
              {/* Facebook login button with icon in a circle */}
              <IconButton
                color="primary"
                onClick={handleFacebook}
                style={{ padding: 8 }}
              >
                <Avatar style={{ backgroundColor: "#1877f2" }}>
                  <Facebook />
                </Avatar>
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              {/* Google login button with icon in a circle */}
              <IconButton
                color="warning"
                onClick={handleGoogle}
                style={{ padding: 8 }}
              >
                <Avatar style={{ backgroundColor: "#c44747" }}>
                  <Google />
                </Avatar>
              </IconButton>
            </Grid>
          </Grid>
          <Typography
            variant="p"
            gutterBottom
            style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
          >
            Already a User? <Link to="/signup">Log in</Link>
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
          >
            Or
          </Typography>
          
          <Typography
            variant="p"
            gutterBottom
            style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
          >
            Create Your Account
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  fullWidth
                  margin="normal"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <TextField
              label="Email"
              fullWidth
              margin="normal"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label="Create Password"
              fullWidth
              margin="normal"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <TextField
              label="Confirm Password"
              fullWidth
              margin="normal"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} fullWidth>
                    <DatePicker
                      label="Date Of Birth"
                      name="dob"
                      value={formData.dob}
                      onChange={(value) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          dob: value,
                        }))
                      }
                      fullWidth
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    label="Gender"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              label="Postal Code"
              fullWidth
              margin="normal"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
            />
            <TextField
              label="Address"
              fullWidth
              margin="normal"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            <TextField
              label="Province"
              fullWidth
              margin="normal"
              name="province"
              value={formData.province}
              onChange={handleChange}
            />
            <TextField
              label="City"
              fullWidth
              margin="normal"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            <TextField
              label="Country"
              fullWidth
              margin="normal"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
            <TextField
              label="Phone Number"
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Your Role</InputLabel>
              <Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                label="Your Role"
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="politician">Politician</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              style={{ color: "grey" }}
              control={
                <Checkbox
                  name="agreement"
                  checked={formData.agreement}
                  onChange={handleChange}
                />
              }
              label="By clicking Sign Up, you agree to our Terms of Service."
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!formData.agreement}
              style={{
                marginTop: 16,
                backgroundColor: "#4fe3e3",
                color: "black",
                borderRadius: "10px", // Adjust the border-radius as needed
                padding: "10px", // Adjust the padding as needed
                // Adjust the font weight as needed
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add box shadow for depth
              }}
            >
              Sign Up
            </Button>
          </form>
          {submitSuccess && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ color: "green" }}>
                Sign Up Successful!
              </Typography>
              <IconButton onClick={() => setSubmitSuccess(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {submitError && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" style={{ color: "red" }}>
                {submitError}
              </Typography>
              <IconButton onClick={() => setSubmitError("")}>
                <CloseIcon />
              </IconButton>
            </div>
          )}

          
        </Paper>
      </Container>
    </Container>
  );
};

export default Signup;
