// src/components/LoginPage.js
import { Avatar, IconButton, Link } from "@mui/material";
import { Facebook, Google } from "@mui/icons-material";
import React, { useState } from "react";

import {
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  CssBaseline,
  Grid,
} from "@mui/material";
import axios from "axios";
import "./login.css";
import CloseIcon from "@mui/icons-material/Close";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleLogin = () => {
    // Add your authentication logic here
    const BASE_URL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios
      .post(
        `${BASE_URL}/auth/login`,
        { email, password },
        { withCredentials: true }
      )
      .then(() => {
        setSubmitSuccess(true);
        setEmail("");
        setPassword("");

        window.location.href = "/candidates";
      })
      .catch((err) => {
        console.log(err);
        setSubmitError(`Failed to login: ${err.response.data.message ?? err.response.data}`);
        setSubmitSuccess(false);
      });
  };

  const handleFacebook = (e) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    window.location.href = `${BASE_URL}/auth/facebook`;
  };

  const handleGoogle = (e) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    window.location.href = `${BASE_URL}/auth/google`;
  };

  return (
    <Container component="main" maxWidth="xs" style={{ "min-height": "500px" }}>
      <CssBaseline />
      <Paper
        className="mt-5"
        elevation={3}
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        {submitSuccess && (
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <Typography variant="body1" style={{ color: "green" }}>
              Sign In successfully!
            </Typography>
            <IconButton onClick={() => setSubmitSuccess(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {submitError && (
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <Typography variant="body1" style={{ color: "red" }}>
              {submitError}
            </Typography>
            <IconButton onClick={() => setSubmitError("")}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
         <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          Sign In
        </Typography>
        
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {/* Facebook login button with icon in a circle */}
            <IconButton
              color="primary"
              onClick={handleFacebook}
              style={{ padding: 8 }}
            >
              <Avatar style={{ backgroundColor: "#1877f2" }}>
                <Facebook />
              </Avatar>
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            {/* Google login button with icon in a circle */}
            <IconButton
              color="warning"
              onClick={handleGoogle}
              style={{ padding: 8 }}
            >
              <Avatar style={{ backgroundColor: "#c44747" }}>
                <Google />
              </Avatar>
            </IconButton>
          </Grid>
        </Grid>
        <Typography
          variant="p"
          gutterBottom
          style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
        >
          New User? <Link to="/signup">Sign Up</Link>
        </Typography>
        <Typography
          variant="p"
          gutterBottom
          style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
        >
          Or
        </Typography>
        <Typography
          variant="p"
          gutterBottom
          style={{ marginTop: "10px", color: "grey", textAlign: "center" }}
        >
          Sign In With Email
        </Typography>
       
        <form style={{ width: "100%", marginTop: 8 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={{
              marginTop: 16,
              backgroundColor: "#4fe3e3",
              color: "black",
              borderRadius: "10px", // Adjust the border-radius as needed
              padding: "10px", // Adjust the padding as needed
              // Adjust the font weight as needed
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add box shadow for depth
            }}
          >
            Log In
          </Button>
        </form>
        
        
      </Paper>
    </Container>
  );
};

export default LoginPage;
