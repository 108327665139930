import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Rating } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import axios from "axios";

import "./CommentsForm.css";

const theme = createTheme();

const PREFIX = "CommentForm";
const classes = {
  formContainer: `${PREFIX}-formContainer`,
  customTextField: `${PREFIX}-customTextField`,
  reviewSection: `${PREFIX}-reviewSection`,
  reviewSectionItem: `${PREFIX}-reviewSectionItem`,
  customLabel: `${PREFIX}-customLabel`,
  customizedIcons: `${PREFIX}-customizedIcons`,
};

const FormContainer = styled(Grid)(({ theme }) => ({
  [`&.${classes.formContainer}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap:'wrap',
    gap:'1em',
    justifyContent: "space-between",
    alignItems:'center',
    width:'100%',

   
  },
  [`&.${classes.customTextField}`]: {
    background: "#F9F9F9",
    height:'5em',
    "& input::placeholder": {
      color: "var(--second-text-color, #737373)",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
  [`& .${classes.reviewSection}`]: {
    margin: "1em auto 1em 0 ",
    flex:'1',
    minWidth:'250px'
  },
  [`& .${classes.reviewSectionItem}`]: {
    display: "flex",
    flexDirection: "row",
    

    justifyContent: "space-between",
    gap: "1em",
    alignItems: "base-line",
  },
  [`& .${classes.customLabel}`]: {
    color: "#787676",
    fontSize: "0.6rem",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "@media (min-width:1600px)": {
     
    [`& .${classes.customLabel}`]: {
      fontSize:'1rem'
    }

  }
}));

const CustomButton = styled(Button)({
  color: "#F9F9F9",
  margin: "0.5em auto  0",
  borderRadius: "0.3125rem",
  background: "#4FD3D3",
  fontSize: "0.7rem",
  fontStyle: "normal",
  fontWeight: "700",
  width:'100%',
  
  "&:hover": {
    background: "#3CAFAF",
  },
});

const CommentsForm = ({ card, refetch }) => {
  const { control } = useForm();
  const [comment, setComment] = useState("");

  const [stars, setStars] = useState({
    ratingRespondstoConstituents: 0,
    ratingDemonstratesIntegrity: 0,
    ratingRepresentsmyInterests: 0,
    ratingEffectivePolicies: 0,
  });

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleChangeStars = (e, n) => {
    const name = e.target.name;
    setStars((prev) => {
      return {
        ...prev,
        [name]: n,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const BASE_URL = process.env.REACT_APP_API_URL;
    const politicianId = card._id;
    axios
      .post(
        `${BASE_URL}/api/comment`,
        {
          content: comment,
          politician: politicianId,
          respondsToConstituents: stars.ratingRespondstoConstituents,
          demonstratesIntegrity: stars.ratingDemonstratesIntegrity,
          effectivePolicies: stars.ratingEffectivePolicies,
          representsMyInterests: stars.ratingRepresentsmyInterests,
        },
        { withCredentials: true }
      )
      .then((res) => {
        console.log(res.data);
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="comments-form-container">
      <h2>Review this politician</h2>
      <div className="comments-form">
        

        <form onSubmit={handleSubmit}>
          <ThemeProvider theme={theme}>
            <FormContainer  className={classes.formContainer}>
            <Grid className={classes.reviewSection}>
              <h3>Rate Politician:</h3>
                <Grid className={classes.reviewSectionItem}>
                  <Typography variant="p" className={classes.customLabel}>
                    Responds to Constituents
                  </Typography>
                  <Controller
                    name="ratingRespondstoConstituents"
                    control={control}
                    defaultValue={0}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                      formState,
                    }) => (
                      <Rating
                        name="ratingRespondstoConstituents"
                        size="medium"
                        onChange={(event, newValue) => {
                          handleChangeStars(event, newValue);
                          onChange(newValue);
                        }}
                        value={stars.ratingRespondstoConstituents}
                        className={classes.customizedIcons}
                      />
                    )}
                  />
                </Grid>
                <Grid className={classes.reviewSectionItem}>
                  <Typography variant="p" className={classes.customLabel}>
                    Demonstrates Integrity
                  </Typography>
                  <Controller
                    name="ratingDemonstratesIntegrity"
                    control={control}
                    defaultValue={0}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                      formState,
                    }) => (
                      <Rating
                        name="ratingDemonstratesIntegrity"
                        onChange={(event, newValue) => {
                          handleChangeStars(event, newValue);
                          onChange(newValue);
                        }}
                        value={stars.ratingDemonstratesIntegrity}
                        className={classes.customizedIcons}
                      />
                    )}
                  />
                </Grid>
                <Grid className={classes.reviewSectionItem}>
                  <Typography variant="p" className={classes.customLabel}>
                    Represents my Interests
                  </Typography>
                  <Controller
                    name="ratingRepresentsmyInterests"
                    control={control}
                    defaultValue={0}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                      formState,
                    }) => (
                      <Rating
                        name="ratingRepresentsmyInterests"
                        onChange={(event, newValue) => {
                          handleChangeStars(event, newValue);
                          onChange(newValue);
                        }}
                        value={stars.ratingRepresentsmyInterests}
                        className={classes.customizedIcons}
                      />
                    )}
                  />
                </Grid>
                <Grid className={classes.reviewSectionItem}>
                  <Typography variant="p" className={classes.customLabel}>
                    Effective Policies
                  </Typography>
                  <Controller
                    name="ratingEffectivePolicies"
                    control={control}
                    defaultValue={0}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                      formState,
                    }) => (
                      <Rating
                        name="ratingEffectivePolicies"
                        onChange={(event, newValue) => {
                          handleChangeStars(event, newValue);
                          onChange(newValue);
                        }}
                        value={stars.ratingEffectivePolicies}
                        className={classes.customizedIcons}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Stack flex={1} width={"100%"} minWidth={'300px'}  className="comment-wrapper" >
              <h3 className="mt-3">Comment</h3>
                <TextField
                  className={classes.customTextField}
                  id="comment"
                  name="comment"
                  label="Comment"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={comment}
                  border={"red"}
                  onChange={handleCommentChange}
                  required
                />
                <CustomButton type="submit">Submit Your Review</CustomButton>
              </Stack>
                
              
             
              {/* <Grid item xs={12}>
              <div className="form-group">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="stars">Overall Star Rating:</InputLabel>
                  <Select
                    id="stars"
                    name="stars"
                    label="Overall Star Rating"
                    value={stars}
                    onChange={handleStarsChange}
                  >
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <MenuItem key={rating} value={rating}>
                        {rating} Star{rating !== 1 ? "s" : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label>Choose Qualities:</label>
                <ButtonGroup fullWidth>
                  {[
                    "Leadership",
                    "Decision-making ability",
                    "Problem-solving skills",
                    "Accountability",
                    "Resilience",
                  ].map((option) => (
                    <Button
                      key={option}
                      variant={qualities.includes(option) ? "contained" : "outlined"}
                      color="primary"
                      onClick={() => toggleQuality(option)}
                      sx={{
                        borderRadius: "25px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        width: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        padding: "25px 10px"
                      }}
                    >
                      {option}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </Grid> */}
            </FormContainer>
            
          </ThemeProvider>
        </form>
      </div>
    </div>
  );
};

export default CommentsForm;
