import { Typography, Rating, Stack } from "@mui/material";
import "./CardDetail.css";
import React from "react";


export default function OverallRatingComponent({overalRating,nbrRatingInsert}){
    return (
        <Stack
                className="totalRating"
                direction={"row"}
                sx={{ width: "100%", maxWidth: "250px" }}
              >
                <h2 className="totalreview">{overalRating.toFixed(1)}</h2>
                <Stack direction="column" sx={{ marginLeft: "0" }}>
                  <Rating
                    name="read-only"
                    value={overalRating}
                    readOnly
                    size="medium"
                  />

                  <Typography variant="p" className="p-rating-total">
                    Based on {nbrRatingInsert} ratings
                  </Typography>
                </Stack>
              </Stack>
    )
}